import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';

const CustomerReview = ({review, ...props}) => {
    return (
       <Row>
           <Col xs={12}>
               <h4>Patient Review</h4>
               <Card className='mb-2'>
                   <CardBody>
                   Dr. Rakesh Mishra is a good professional and has decent behaviour. He gave time and listen to my problem. With this correct diagnosis and medicine, I got cured very soon. He is one of the Best Physician in Ranchi and I recommend him as a superb Family Doctor and for those who are suffering from chronic illness. Thanks alot Dr. Rakesh.
                   </CardBody>
               </Card>
               <Card className='mb-2'>
                   <CardBody>
                   Extremely humble *** ************** Doesn't make you wait and respect and attends to all his patients with sincerity and regularly follows up. Highly recommended. Ranchi needs more doctors like him . Honest and humble
                   </CardBody>
               </Card>
               <Card className='mb-2'>
                   <CardBody>
                   Dr. Rakesh Mishra is a good professional and has decent behaviour. He gave time and listen to my problem. With this correct diagnosis and medicine, I got cured very soon. He is one of the Best Physician in Ranchi and I recommend him as a superb Family Doctor and for those who are suffering from chronic illness. Thanks alot Dr. Rakesh.
                   </CardBody>
               </Card>
               <Card className='mb-2'>
                   <CardBody>
                   Extremely humble *** ************** Doesn't make you wait and respect and attends to all his patients with sincerity and regularly follows up. Highly recommended. Ranchi needs more doctors like him . Honest and humble
                   </CardBody>
               </Card>
           </Col>
       </Row> 
    )
}

export default CustomerReview;