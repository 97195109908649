import React, {useEffect, useState} from "react";

const DaySelect = ({select, ...props}) => {
    const [day, setDay] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    useEffect(() => {
        let days = [
            {"slot": "SUN", "day_id": "0"},
            {"slot": "MON", "day_id": "1"},
            {"slot": "TUE", "day_id": "2"},
            {"slot": "WED", "day_id": "3"},
            {"slot": "THUS", "day_id": "4"},
            {"slot": "FRI", "day_id": "5"},
            {"slot": "SAT", "day_id": "6"},
            
        ] ;
        setDay(days);
    },[])

    let getSelected = (t) => {
        console.log( t)
        let i = [...selectedIndex];

        let no = i.findIndex((el)=> el == t.day_id);

        if (no == -1) {
            i.push(t.day_id);
        }else{ 
            i.splice(no, 1);
        }

        console.log(i);

        
        setSelectedIndex(i);
        select(i);
    }
    return (
        
        <div className='timeSlotPicker'>
            {day?.length > 0 && day.map((row, index) => {
                return(
                    <div className={selectedIndex.find((i)=> i == row.day_id) ? 'timeSlot active' : 'timeSlot'} 
                    onClick={() => getSelected(row)} >
                        {row.slot}
                    </div>
                )
            })
            }    
        </div>
    )
}

export default DaySelect;