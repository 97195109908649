import React from 'react';
import { imageUrl } from '../../hocs/mainUrl';

import {
    Row,
    Col,
    Card,
    CardBody,

} from 'reactstrap';

import { FaThumbsUp, FaCheckCircle } from 'react-icons/fa';

const DocDetail = ({data, ...props}) => {
    
    
    return (
        <Card className='mt-2'>
            <CardBody>
                <Row>
                    <Col xs="2">
                    { data?.profile_image === "" ?

                        <img className='img-fluid rounded-circle' src={require("../../../assets/img/doc_d.png")} alt="Img" />
                        :
                        <img className='img-fluid rounded-circle' src={imageUrl + data?.profile_image + ".png"} alt="Img" />
                    }
                     
                    </Col>
                    <Col xs="10 mb-1">
                        <h4 color='primary-text'>{data?.name}</h4>
                        <p className='m-0'>{data?.degree}</p>
                        <p className='m-0'></p>
                        {/*<p className='m-0'>{data?.exp} Experience Overall </p>*/}
                        
                        <div className='mb-1 mt-1 d-flex align-items-center'>
                            <FaCheckCircle color="#00a500"/><span className='ps-1' >Medical Registration Verified</span>
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                            <FaThumbsUp color="#00a500"/> 
                            <span className='ps-1'>90%</span>
                            <small className='ps-2'>(636 votes)</small>
                        </div>

                        <div className="borderDashed mt-1 mb-1"></div>
                        <p>----</p>
                    </Col>

                </Row>
            </CardBody>
        </Card>

    )

}
export default DocDetail;