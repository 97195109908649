import React from 'react';
import { useNavigate } from 'react-router-dom';

import {Button} from 'reactstrap';

const AppointmentDetails = ({data, ...props}) => {

    const navigate = useNavigate();
    
    const rebooking_handler = () => {
        console.log("re-booking");

        sessionStorage.setItem('doc_id', data.doctor_id);
        sessionStorage.setItem('department_id', data.deperment_id);
        
        navigate('/appointment', { state: { rebooking: true, doc_id: data.doctor_id, booking_id: data.booking_id, } });
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h4 color='primary-text'>{data?.name}</h4>
                    <p className='m-0'>{data?.degree}</p>
                    <p className='m-0'>{data?.deparment_name}</p>
                </div>
                <div>
                    {   data?.cancel_status === "1" ? 
                        <div>
                            <div className='text-danger mb-2'><b>Status: </b>Cancelled</div>
                            <Button className='text-white' size="sm" color='success' onClick={rebooking_handler}> Re-booking </Button>
                        </div> :
                        <div className='text-success'><b>Status: </b>Confirm</div>
                    }
                </div>

            </div>
            

            <div className="borderDashed mt-1 mb-1"></div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Appointment Number</div>
                <h6>{data?.booking_id}</h6>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Date of Appointment</div>
                <h6>{data?.booking_date?.split(" ")[0].split('-').reverse().join('-')}</h6>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Timing</div>
                <h6>{data?.slot}</h6>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Appointment Fee</div>
                <h6>&#8377; {data?.fee}</h6>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Fee Status</div>
                <h6 className='text-success'>Paid</h6>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Clinic Address</div>
                <h6 className='text-end'>
                    <p className='m-0 '>{data?.address}</p>
                </h6>
            </div>
            <div className="borderDashed mt-1 mb-1"></div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Patient Name</div>
                <h5 >{data?.patient_name} </h5>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='fw-bold'>Patient Mobile Number</div>
                <h6 >+91 {data?.ph_no}</h6>
            </div>
        </div>
    )
}

export default AppointmentDetails;