import React,{ useState } from 'react';
import axios from 'axios';
import { imageUrl, mainUrl } from '../hocs/mainUrl';
import { notification } from '../hocs/notification';

import { Row, Col, Card, CardFooter, CardBody, Button, Spinner } from 'reactstrap';

const DoctorDisplay = ({data, reset, ...props}) => {
    const [submitLoading, setSubmitLoading] = useState(false);

    const active_inactive_handler = (status) => {
        setSubmitLoading(true);
        let postData = {"user_id": data.user_id, "input_data": status}
        axios.post(mainUrl + 'Admin/doctor_active', postData)
            .then(function (response) {
                let notify = notification({ message: "Data send successfully.", type: 'success' });
                notify();
               reset();
               setSubmitLoading(false);
            })
            .catch(function (error) {
                let notify = notification({ message: "Data not send! Try Again", type: 'error' });
                notify(); 
                setSubmitLoading(false);
            });
    }

    return(
        <Col md={4} className="mb-2">
            <Card className='h-100'>
                <CardBody>
                    <Row>
                        <Col xs={2}>
                            <img src={imageUrl + data.image_id + ".png"} alt="" height="75px" />
                        </Col>
                        <Col xs={10} className="text-end">
                            <h6>{data.name}</h6>
                            <div>{data.degree}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <b>Mobile No.</b>
                        </Col>
                        <Col xs={8} className="text-end">
                            +91 {data.ph_no}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <b>Email</b>
                        </Col>
                        <Col xs={8} className="text-end">
                            {data.user_name}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <b>Clinic Fee</b>
                        </Col>
                        <Col xs={8} className="text-end">
                        &#8377; {data.fee}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <b>Clinic Address</b>
                        </Col>
                        <Col xs={8} className="text-end">
                            {data.address}
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    { data.login_status === "1" ?
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><b>Current Status: </b> <span className='text-success'>Active</span></div>
                            <Button className='text-white' color="danger" size='sm' onClick={()=>active_inactive_handler(0)} disabled={submitLoading}>
                                Change to In-active
                                {submitLoading && <Spinner color="secondary" className="ms-2" size="sm">loading...</Spinner>}
                            </Button>
                            
                        </div>
                        :
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><b>Current Status: </b> <span className='text-danger'>In-Active</span></div>
                            <Button className='text-white' color="success" size='sm' onClick={()=>active_inactive_handler(1)} disabled={submitLoading}>
                                Change to Active
                                {submitLoading && <Spinner color="secondary" className="ms-2" size="sm">loading...</Spinner>}
                            </Button>
                        </div>    
                    }
                </CardFooter>
            </Card>
        </Col>
    )
}
export default DoctorDisplay;