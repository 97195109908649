import React from 'react';

import {Row, Col, Card, } from 'reactstrap';

const ProfileHome = ({name, detail, imgSrc, ...props}) => {
    return(
        <Card className="primaryBg">
            <Row>
                <Col xs={5} className="text-center p-2">
                    <img src={imgSrc} height="180px" alt="load" className='rounded-circle' />
                </Col>
                <Col xs={7} className="d-flex align-items-center">
                    <div>
                        <h3>{name}</h3>
                        <h5>{detail}</h5>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default ProfileHome;