import React from 'react';
import Header from '../component/layout/Header';
import Slider from '../component/utility/Slider';
import {
    Row,
    Col,
} from 'reactstrap';

import {FaHourglassHalf} from 'react-icons/fa';

function WIP() {
    return (
        <div>
            <Header />
            <Slider />

            <Row className='p-2'>
                <Col xs="12" className='text-center'>
                    <FaHourglassHalf size={35} className="fa-spin"/>
                   <h1>Work in Progress</h1> 
                </Col>
            </Row>
        </div>
    )
}

export default WIP;