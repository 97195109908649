import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { notification } from '../hocs/notification';
import {Link} from 'react-router-dom';
import Logo from './../../assets/img/logo.png'; 
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import {FaUserAlt} from "react-icons/fa";


function Header() {
    const navigate = useNavigate();
    const [menuShow, setMenuShow] = useState(false);

    useEffect(() => {
        
        if( localStorage.getItem('loggedIn') !== 'true' ){
            navigate('/');
        }

    },[]);

    const logout_click_handler = () => {
        
        let notify = notification({ message: "You logout successfully.", type: 'success' });
		notify();
        localStorage.clear(); 
        navigate('/');
    }  

    return (
        <div className='headerPage'>
            <Navbar
                className='headerNav borderB'
                color="light"
                expand="lg"
                fixed="top"
                light
            >
                <NavbarBrand href="/">
                    <img className='pe-2' width="75px" src={Logo} alt="logo" />
                    <b className='h4 text-primary'>DOOR TO DOCTOR</b>
                </NavbarBrand>
                <NavbarToggler onClick={() => { setMenuShow(!menuShow) }} />
                <Collapse navbar isOpen={menuShow}>
                    <Nav
                        className="ms-auto"
                        navbar
                    >
                        <NavItem>
                            <Link to="/home">
                                <NavLink> HOME </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/manage-Doctor">
                                <NavLink> MANAGE DOCTORS </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/add-Doctor">
                                <NavLink> ADD DOCTORS </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/BookingReport">
                                <NavLink> REPORT </NavLink>
                            </Link>
                        </NavItem>
                        
                        <UncontrolledDropdown
                            inNavbar
                            nav
                            >
                            <DropdownToggle
                                caret
                                nav
                            >
                                <FaUserAlt />
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem>
                                    <Link style={{fontWeight: 400}} to="/appointmentList">All Appointments</Link>
                                </DropdownItem>
                                <DropdownItem divider />
                                
                                <DropdownItem 
                                    onClick={ logout_click_handler}>
                                    LOGOUT
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        
                    </Nav>
                    
                </Collapse>
            </Navbar>
        </div>
    )
}

export default Header;