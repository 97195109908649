import React, {useState, useEffect} from 'react';
import Header from './../component/layout/Header';
import Footer from './../component/layout/Footer';
import DisplayBox from '../component/utility/home/DisplayBox';
import GraphView from '../component/utility/home/GraphView';
import AppointmentList from '../component/utility/appointment/AppointmentList';
import AppointmentDetails from '../component/utility/appointment/AppointmentDetails';

import TextHeader from '../component/utility/home/TextHeader';
import LoadingSpinner from '../component/utility/LoadingSpinner';

import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';


import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody,
    Spinner

} from 'reactstrap';



const Home = () => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [allCount, setAllCount] = useState(null);
    const [currAppointment, setCurrAppointment] = useState(null);
    const [futureAppointment, setFutureAppointment] = useState(null);
    const [oldAppointment, setOldAppointment] = useState(null);
    const [appointmentDtl, setAppointmentDtl] = useState(null);

    useEffect(() => {
        setLoading(true);
        setLoading2(true);
        axios.post(mainUrl + 'Admin/all_count')
        .then(function (response) {
            setLoading(false);
            setLoading2(false);
            setAllCount(response.data);
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false); 
        });
        
        
        axios.post(mainUrl + 'Admin/appointments_list_admin')
        .then(function (response) {
           
           if(response.data.appointments_list_today[0].booking_id == 0){
            setCurrAppointment(null);
           }else{
            setCurrAppointment(response.data.appointments_list_today);
           }
           
           if(response.data.appointments_list_future[0].booking_id == 0){
            setFutureAppointment(null);
           }else{
            setFutureAppointment(response.data.appointments_list_future);
           }

           setOldAppointment(response.data.appointments_list_old);
        })
        .catch(function (error) {
            console.log(error); 
        });


    }, [])

    const openOrderDetail = (id) => {
        // appointment details API
        let postData = {"booking_id" : id}
        axios.post(mainUrl + 'Booking/booking_details', postData)
        .then(function (response) {
            console.log(response.data);
            setAppointmentDtl(response.data[0]);
            setOpenModal(true);
        })
        .catch(function (error) {
            console.log(error); 
        });
    }
    return (
        <div>
            <Header />
            {loading &&
                <div className='loadPage'>
                    <Spinner className="ms-2" color="primary" size="lg">loading...</Spinner>
                </div>
            }
            <Row className='p-2'>
                <Col md={3}>
                    <DisplayBox name="Total Doctor" icon="1" value={allCount?.doctor} />
                </Col>
                <Col md={3}>
                    <DisplayBox name="Appointments" icon="3" value={allCount?.booking} />
                </Col>
                <Col md={3}>
                    <DisplayBox name="Total Patients" icon="4" value={allCount?.patient} />
                </Col>
                <Col md={3}>
                    <DisplayBox name="Payment" icon="2" value={Number(allCount?.payment).toFixed(2)} />
                </Col>
                
            </Row>

            <GraphView />

            <div className='borderBottom'></div>

            <Row className="m-2">
                <Col md={12} className="primaryBg rounded textWhite mb-2"> 
                    <h4>Appointments List</h4>
                </Col>
               <Col md={4}>
                    <TextHeader name="Today" bgColor="primary" />
                    {currAppointment &&
                        currAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="primary" />
                            )
                        })
                    }
                    {!currAppointment && 
                        <Card className='mb-2 cursor' color="primary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
               </Col>
               <Col md={4}>
                    <TextHeader name="Future" bgColor="warning" />
                    {futureAppointment &&
                        futureAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="warning" />
                            )
                        })
                    }
                    {!futureAppointment && 
                        <Card className='mb-2 cursor' color="warning" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
               </Col>
               <Col md={4}>
                    <TextHeader name="Completed" bgColor="secondary" />

                    {oldAppointment &&
                        oldAppointment.slice(0, 6).map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="secondary" />
                            )
                        })
                    }
                    {!oldAppointment && 
                        <Card className='mb-2 cursor' color="secondary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
               </Col>
            </Row>

            <Footer />

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={openModal}
            >
                <ModalHeader toggle={() => setOpenModal(false)}>
                    Appointment Details
                </ModalHeader>
                <ModalBody>
                    
                    <AppointmentDetails data={appointmentDtl} />
                </ModalBody>

            </Modal>
            {
                loading2 &&
                <LoadingSpinner />
            }
        </div>
    )
}

export default Home;