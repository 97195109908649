import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../component/layout/Header";
import Footer from "../component/layout/Footer";
import Banner from "../component/utility/Banner";
import { notification } from "../component/hocs/notification";
import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';
import Slot from "../component/utility/addDoctor/Slot";
import LoadingSpinner from "../component/utility/LoadingSpinner";

import FileBase64 from 'react-file-base64';
import {FaLocationArrow} from 'react-icons/fa';
import { Row, Col, Card, CardBody, Form, Input, Label, Button, Spinner, InputGroup, InputGroupText } from "reactstrap";
import DaySelect from "../component/utility/addDoctor/DaySelect";


const AddDoctor = () => {
    
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [locOpt, setLocOpt] = useState([]);
    const [deptOpt, setDeptOpt] = useState([]);
    const [loc, setLoc] = useState("0");
    const [dept, setDept] = useState("0");
    const [fName, setFName] = useState(null);
    const [mobNo, setMobNo] = useState(null);
    const [experience, setExperience] = useState(null);
    const [degree, setDegree] = useState(null);
    const [fee, setFee] = useState(null);
    const [email, setEmail] = useState(null);
    const [pwd, setPwd] = useState(null);
    const [cpwd, setCpwd] = useState(false);
    const [address, setAddress] = useState(null);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const [slotIds, setSlotIds] = useState([]);
    const [dayIds, setDayIds] = useState([]);
    const [imgPath, setImgPath] = useState(null);

    useEffect(() => {
        axios({
            method: 'post',
            url: mainUrl +'Doctor/all_city',
            headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let list = response.data.map(row => {
                    return(
                        <option value={row.city}> {row.city_name} </option>
                    )
                })
                setLocOpt(list);
            })
            .catch(function (error) {
            console.log(error); 
            });
        
        axios({
            method: 'post',
            url: mainUrl + 'Doctor/all_deparment',
            headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                let dept = response.data.map(row => {
                    return(
                        <option value={row.deparment_id}> {row.deparment_name} </option>
                    )
                })
                setDeptOpt(dept);
            })
            .catch(function (error) {
                console.log(error); 
            });
    },[])

    const getSelectedSlot = (ar) => {
        setSlotIds(ar);
    }
    const getSelectedDays = (ar) => {
        setDayIds(ar);
    }

    const add_doctor_handler = (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        
        let valid = true;
        if(loc === "0"){
            valid = false;
            let notify = notification({ message: "Please select Location.", type: 'error' });
		    notify();
        }
        if(dept === "0"){
            valid = false;
            let notify = notification({ message: "Please select Department.", type: 'error' });
		    notify();
        }
        if(slotIds.length === 0){
            valid = false;
            let notify = notification({ message: "Please select Appointment slot.", type: 'error' });
		    notify();
        }
        if(dayIds.length === 0){
            valid = false;
            let notify = notification({ message: "Please select Day slot.", type: 'error' });
		    notify();
        }
        console.log(imgPath);
        if(imgPath === null || imgPath === undefined){
            valid = false;
            let notify = notification({ message: "Please select Image.", type: 'error' });
		    notify();
        }

        let fdata = {
            "name": fName,
            "ph_no": mobNo,
            "email_id": email,
            "password": pwd,
            "city_id": loc,
            "deparment_id": dept,
            "digree": degree,
            "exp": experience,
            "fee": fee,
            "slot_id": slotIds,
            "day_id": dayIds,
            "address": address,
            "latitude": lat,
            "longitude": long,
            "img_path": imgPath
        }

        if(valid){
            setLoading(true);
            axios.post(mainUrl + 'Admin/doctor_add11', fdata)
                .then(function (response) {
                    if(response.data.register == 0){
                        let notify = notification({ message: "User email already register.", type: 'error' });
		                notify();
                    }else{
                        let notify = notification({ message: "Doctor Added Sucessfully.", type: 'success' });
		                notify();
                        navigate('/home');
                    }
                    setLoading(false);
                    setSubmitLoading(false);

                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error); 
                    setSubmitLoading(false);
                });

        }else{
            setSubmitLoading(false);
            setLoading(false);
        }
        
    }

    const checkPwd = (p) => {
        if(pwd === p){
            setCpwd(true);
        }else{
            setCpwd(false);
        }
    } 

    const get_current_location = () => {
        console.log("in")
        const location = window.navigator && window.navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
            })
        }else{
            let notify = notification({ message: "Not getting location!Try again", type: 'error' });
		    notify();
        }        
    }

    return(
        <div>
            <Header />
            <Row>
                <Banner btn={false} />
                <div className='borderBottom'></div>
            </Row>
            <Row className="p-2">
                <Col md={12} className="primaryBg rounded textWhite mb-2"> 
                    <h4>Add Doctor</h4>
                </Col>
                <Col md={8} className="">
                    <Card>
                        <CardBody>
                            <Form onSubmit={add_doctor_handler}>
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Clinic Name / Doctor Name</Label>
                                        <Input type="text" placeholder="Dr. Name / Clinic Name" maxLength={50} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} onChange={(e) => setFName(e.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Mobile Number</Label>
                                        <InputGroup>
                                            <InputGroupText>+91</InputGroupText>
                                            <Input 
                                                type="text"
                                                maxLength={10}
                                                pattern="[0-9]*"
                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                placeholder="Mobile Number" 
                                                onChange={(e) => setMobNo(e.target.value)} required />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <Label>Experience</Label>
                                        <InputGroup>
                                            <Input type="text" placeholder="Experience" maxLength={2}
                                            pattern="[0-9]*"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} onChange={(e) => setExperience(e.target.value)} required/>
                                            <InputGroupText>Year</InputGroupText>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Degree</Label>
                                        <Input type="text" placeholder="Degree" onChange={(e) => setDegree(e.target.value)} required />
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <Label>Appointment Fee</Label>
                                        <InputGroup>
                                            <InputGroupText>&#8377;</InputGroupText>
                                            <Input type="text" placeholder="Appointment Fee" maxLength={7}
                                            pattern="[0-9]*"
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} onChange={(e) => setFee(e.target.value)} required/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Location</Label>
                                        <Input
                                            id="exampleSelect"
                                            name="select"
                                            type="select"
                                            onChange={(e) => setLoc(e.target.value)}
                                        >
                                            <option value="0"> --Select Location-- </option>
                                            {locOpt}
                                        </Input> 
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <Label>Department</Label>
                                        <Input
                                            id="exampleSelect"
                                            name="select"
                                            type="select"
                                            onChange={(e) => setDept(e.target.value)}
                                        >
                                            <option value="0"> --Select Department-- </option>
                                            {deptOpt}
                                        </Input>
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Email</Label>
                                        <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Password</Label>
                                        <Input type="password" placeholder="password" onChange={(e) => setPwd(e.target.value)} required />
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <Label>Confirm password</Label>
                                        <Input type="password" placeholder="Confirm password" onChange={(e) => checkPwd(e.target.value)} required valid={cpwd} invalid={!cpwd} />
                                    </Col>
                                </Row>
                                   
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <Label>Address</Label>
                                        <Input type="textarea" placeholder="Address" maxLength={200} onKeyPress={(e) => !/^[a-zA-Z\s]*$/.test(e.key) && e.preventDefault()} onChange={(e) => setAddress(e.target.value)} required />
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <Row>
                                            <Col md={6} className="mb-2">
                                                <Label>Latitude</Label>
                                                <Input type="number" pattern="[0-9]*" value={lat} placeholder="Latitude" onChange={(e) => setLat(e.target.value)} required />
                                            </Col>
                                            <Col md={6} className="mb-2">
                                                <Label>Longitude</Label>
                                                <Input type="number" pattern="[0-9]*" value={long} placeholder="Longitude" onChange={(e) => setLong(e.target.value)} required />
                                            </Col>
                                            <Col md={12} className="mb-2">
                                                <span className="cursor text-success" onClick={get_current_location}> <FaLocationArrow color="#00a500" size={20} /> <b>Get your current Location</b></span>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row> 
                                <Row>
                                    <Col md={12} className="mb-2">
                                        <Label>Add Working Day</Label>
                                        <DaySelect select={getSelectedDays} />
                                        
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col md={12} className="mb-2">
                                        <Label>Add Appointment Slot</Label>

                                        <Slot select={getSelectedSlot}/>
                                        
                                    </Col>
                                </Row> 


                                <Row>
                                    <Col md={12} className="mb-2">
                                        <Button color="primary" disabled={submitLoading}>
                                            Add Doctor
                                            {submitLoading && <Spinner className="ms-2" size="sm">loading...</Spinner>}
                                        </Button>
                                    </Col>
                                </Row>   

                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                   <Card>
                       <img src={require('../assets/img/defaultDoc.png')}  alt="Photo" />
                       <FileBase64
                            multiple={false}
                            onDone={(files)=> setImgPath(files.base64)} 
                        />
                       {/*<Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={(e)=>setImgPath(e.target.files[0])}
                        />*/}
                   </Card>
                </Col>
            </Row>
            <Footer />
            {loading && 
                <LoadingSpinner />
            }
        </div>
    )
}

export default AddDoctor;