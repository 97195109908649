import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../../hocs/notification';
import { todayDate, maxDate } from '../../hocs/formatDate';
import axios from 'axios';
import { mainUrl } from '../../hocs/mainUrl';
import TimeSlotPicker from './TimeSlotPicker';
import LoadingSpinner from '../LoadingSpinner';

import {
    Row, Col, Card, CardHeader, CardBody,
    Input, Label, Alert, Button, Form, FormGroup,
    ModalBody, ModalHeader, Modal, Spinner 

} from 'reactstrap';

const PickTimeSlot = ({data, docDtl, rebook,...props}) => {
    const navigate = useNavigate();
    
    const [timeLoading, setTimeLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeData, setTimeData] = useState(null);
    const [selectedTime, setSelectedTime] = useState("1");
    const [selectedDate, setSelectedDate] = useState(null);
    const [patient_name, setPatient_name] = useState(null);
    const [patient_mobile, setPatient_mobile] = useState(null);
    const [patient_modal, setPatient_modal] = useState(false);
    const [loginDetails, setLoginDetails] = useState(null);

    useEffect(() => {
        let loginDetails = JSON.parse(localStorage.getItem('loginDetails'));
        setLoginDetails(loginDetails);
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    },[])

    const loadScript = (url) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
    
            script.src = url;
            script.onload = () => {
                resolve(true);
            }

            script.onerror = () => {
                resolve(false);
            }

            document.body.appendChild(script);
        })

    }

    useEffect(() => {
        if(localStorage.getItem('loggedIn') !== 'true'){
        }
        
        setTimeData(data);
    }, [data])

    const selectTime = (t) => {
        setSelectedTime(t);
    }

    const book_appointment = (e) => {
        e.preventDefault();
        setLoading(true);

        if(rebook?.rebooking){
            //re-booking api call
            let postData = { "booking_id": rebook.booking_id, 
                "slot_id": selectedTime, 
                "doctor_id": rebook.doc_id, 
                "date":selectedDate,
                "patient_name": patient_name,
                "patient_phone": patient_mobile    
            }
            axios.post(mainUrl + 'Admin/rebooking', postData)
                .then(function (response) {
                    setLoading(false);
                    setPatient_modal(false);
                    let notify = notification({ message: "Your appointment Re-booked successfully.", type: 'success' });
                    notify();
                    navigate('/appointmentList');

                })
                .catch(function (error) {
                    console.log(error); 
                });

        }else{
            let d = new Date (selectedDate);
            let day = d.getDay();
            let postData = {
                "doctor_id" : sessionStorage.getItem('doc_id'),
                "date" : selectedDate,
                "day_id" : day,
                "slot_id" : selectedTime,
                "clint_id" : loginDetails?.user_id,
                "deparment_id" : sessionStorage.getItem('department_id'),
                "ph_no": patient_mobile,
                "patient_name": patient_name,
                "patient_phone": patient_mobile
            }
            
            axios.post(mainUrl + 'Clini/clini_patient_slot_book', postData)
                .then(function (response) {
                    
                    let booking_id = response.data[0].booking_id;
                    let postData2 = {"doctor_id" : sessionStorage.getItem('doc_id'),
                    "slot_id" : selectedTime,
                    "booking_id": booking_id,
                    "amount": docDtl.fee,
                    "user_id" : loginDetails?.user_id,
                    "type" : "booking_slot"}

                    axios.post(mainUrl + 'Payment/generate_order', postData2)
                    .then(function (res) {
                        console.log(res.data);
                        let order_id = res.data.id;
                        const options = {
                            "key": "rzp_test_rlnigPieg5fETU", // Enter the Key ID generated from the Dashboard
                            "amount": docDtl.fee, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                            "currency": "INR",
                            "name": "Door to Doctor",
                            "description": "Welcome",
                            "image": "https://example.com/your_logo",
                            "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                            "handler": function (response){
                                
                                update_paymentDetails(response);
                            },
                            "prefill": {
                                "name": patient_name,
                                "email": "",
                                "contact": patient_mobile
                            },
                        }
        
                        //display the Razor pay window
                        const paymentObject = new window.Razorpay(options);
            
                        paymentObject.open();
                    })
                    .catch(function (error) {
                        setLoading(false);
                        console.log(error); 
                    });
    
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error); 
                });
        }    
    }

    const update_paymentDetails = (res) => {
        console.log(res);
        let postData = {"razorpay_order_id": res.razorpay_order_id , "razorpay_payment_id": res.razorpay_payment_id}
        axios.post(mainUrl + 'Payment/payment_details_update', postData)
            .then(function (response) {
                setLoading(false);
                setPatient_modal(false)
                let notify = notification({ message: "Your appointment book successfully.", type: 'success' });
                notify();
                navigate('/agent-home/');
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error); 
            });


    }

    const showPatientModal_handler = () => {
        if(localStorage.getItem('loggedIn') !== 'true'){
            let notify = notification({ message: "Login required!", type: 'warn' });
		    notify();
        }else{
            if(selectedTime !== null && selectedDate !== null){
                console.log(selectedDate);
                console.log(selectedTime);
                setPatient_modal(true);

            }else{
                let notify = notification({ message: "Please Select Date and Time.", type: 'warn' });
                notify(); 
            }
        }
    }

    const date_change_handler = (e) => {
        setTimeLoading(true);
        setSelectedDate(e.target.value);
        setTimeData(null);
        let d = new Date (e.target.value);
        let day = d.getDay();

        let postData = {"doctor_id" : 1,"date" : e.target.value, "day_id": day}
        axios.post(mainUrl + 'Doctor/slot_already_booked', postData)
            .then(function (response) {
                console.log(response.data);
                setTimeData(response.data.doctor_slot);
                setSelectedTime(null);
                setTimeLoading(false);
            })
            .catch(function (error) {
                console.log(error); 
                setTimeLoading(false);
                setSelectedTime(null);
            });
    } 


        return (
            <>
                <Card className='mt-2'>
                    <CardHeader>
                        <h5 className='text-center text-primary m-0'>PICK A TIME SLOT</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs='12' className='mb-2'>
                                
                                <p className='m-0'><b>Address:</b> </p>
                                <p className='m-0'>{docDtl?.address}</p>
                            </Col>
                            <Col xs="12">
                                <Alert color="warning">
                                After you have submitted the appointment request, we might call to confirm the preferred appointment slot.
                                </Alert>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs='12 mb-2'>
                                <Label for="date"> Select Date </Label>
                                <Input
                                    id="date"
                                    name="date"
                                    placeholder="date placeholder"
                                    type="date"
                                    min={todayDate()}
                                    max={maxDate()}
                                    onChange={(e) => {
                                        date_change_handler(e);
                                    }}
                                />
                            </Col>
                            <Col xs='12 mb-2'>
                                <Label for="date"> Select Available Time Slot </Label>

                                { timeLoading ? 
                                    <Spinner className='mb-2 mt-2'> Loading... </Spinner>
                                :
                                    <TimeSlotPicker data={timeData} select={selectTime} />
                                }
                            </Col>
                            <Col xs='12 mb-2'>
                                <Button size="sm" color="primary" onClick={ showPatientModal_handler} block>Submit</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Modal
                    size="md"
                    centered
                    scrollable
                    isOpen={patient_modal}
                >
                    <ModalHeader toggle={() => setPatient_modal(!patient_modal)}>
                        Patient Information
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e)=>book_appointment(e)}>
                            <FormGroup>
                                <Label for="patientName">
                                    Patient Name
                                </Label>
                                <Input
                                    id="patientName"
                                    name="patientName"
                                    placeholder="Enter patient name"
                                    type="text"
                                    onChange={(e) => setPatient_name(e.target.value) }
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="patientMobNo">
                                    Mobile Number
                                </Label>
                                <Input
                                    id="patientMobNo"
                                    name="patientMobNo"
                                    placeholder="Enter mobile number"
                                    type="text"
                                    maxLength={10}
                                    pattern="[0-9]*"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    value = {patient_mobile}
                                    onChange={(e) => setPatient_mobile(e.target.value) }
                                    required
                                />
                            </FormGroup>
                            <Button size="sm" color="primary" block>Pay Now ( &#8377; {Number(docDtl?.fee).toFixed(2)} )</Button>
                        </Form>
                    </ModalBody>

                </Modal>
                {loading && 
                    <LoadingSpinner />
                }
            </>

    
        )
}
export default PickTimeSlot;