import React, { useState, useEffect } from 'react';
import { notification } from '../component/hocs/notification';

import Header from '../component/layout/Header';
import HeaderDoc from '../component/layout/HeaderDoc';
import Footer from '../component/layout/Footer';

import { todayDate } from '../component/hocs/formatDate';

import DataTable from 'react-data-table-component';

import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';

import {FaEye, FaCalendarTimes, FaSearch} from 'react-icons/fa';

import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardHeader,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    InputGroupText,
    InputGroup,
    

} from 'reactstrap';

const BookingReport = () => {

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [frmDate, setFrmDate] = useState(todayDate());
    const [toDate, setToDate] = useState(todayDate());
    const [roleId, setRoleId] = useState("1");

    const [filterList, setFilterList] = useState('');
    const [bookingList, setBookingList] = useState('');
    const [loginDtl, setLoginDtl] = useState('');

    const [appointmentDtl, setAppointmentDtl] = useState('');

    useEffect(() => {
        let loginDtl = JSON.parse(localStorage.getItem('loginDetails'));
        setLoginDtl(loginDtl);
    }, [])

    const get_appointment_list = (e) => {
        e.preventDefault();
        setBookingList('');
        setLoading(true);

        let postData = { "start_date": frmDate, "end_date": toDate, "role_id": roleId }
        axios.post(mainUrl + 'Admin/booking_report', postData)
            .then(function (response) {
                setLoading(false);
                let sortList = response.data.sort((a, b) => Number(a.user_id) - Number(b.user_id));

                console.log(sortList);
                let init_user = sortList[0].user_id;
                let appo_count = 0;
                let fee_count = 0;
                let temp_list = {};
                let finalList = [];

                sortList.map(row => {
                    if (row.user_id === init_user) {
                        appo_count = appo_count + 1;
                        fee_count = fee_count + Number(row.fee);
                        temp_list = {
                            "user_id": row.user_id,
                            "address": row.address,
                            "name": row.name,
                            "count": appo_count,
                            "fee": <div>&#8377; {fee_count}</div>,
                            "action": <div><FaEye className='cursor' color="#244cfd" onClick={() => get_detail_view(row.doctor_id)} size={25} /></div>
                        }
                    } else {
                        finalList.push(temp_list);
                        temp_list = {};
                        init_user = row.user_id;
                        appo_count = 1;
                        fee_count = Number(row.fee);
                        temp_list = {
                            "user_id": row.user_id,
                            "address": row.address,
                            "name": row.name,
                            "count": appo_count,
                            "fee": <div>&#8377; {fee_count}</div>,
                            "action": <div><FaEye className='cursor' color="#244cfd" onClick={() => get_detail_view(row.doctor_id)} size={25} /></div>
                        }
                    }
                })
                finalList.push(temp_list);

                setBookingList(finalList);
                setFilterList(finalList);

            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }

    const filter_data_handler = (e) => {
        let fData = bookingList.filter (item => {
            const query = e.target.value.toLowerCase();
            console.log(query)
            return (
                item.name.toLowerCase().indexOf(query) >= 0 
            )
        })
        setFilterList(fData);

    }

    const get_detail_view = (id) => {
        let postData = { "start_date": frmDate, "end_date": toDate, "doctor_id": id };

        axios.post(mainUrl + 'Admin/booking_report_under_count', postData)
            .then(function (response) {
                
                if((response.data).length > 0){
                    let list = response.data.map(row => {
                        return(
                            {
                                doctor_id: row.doctor_id,
                                name:row.name,
                                booking_date: row.booking_date.split(" ")[0].split('-').reverse().join('-'),
                                patient: row.patient_name +" ("+ row.ph_no +")",
                                cancel: (row.cancel_status === "0") ? 
                                    <FaCalendarTimes className='cursor' color="#ff4136" onClick={()=>cancel_appointment_handler(row)} size={25} /> : 
                                    "Canceled",
                            }
                        )
                    });
                    setAppointmentDtl(list);
                    setOpenModal(true);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            }); 
    }

    const cancel_appointment_handler = (row) => {
        let postData = {"booking_id": row.booking_id}
        axios.post(mainUrl + 'Admin/booking_cancel', postData)
            .then(function (response) {
              console.log(response.data);  
              get_detail_view(row.doctor_id);
              let notify = notification({ message: "Appointment canceled successfully.", type: 'success' });
		        notify();  
            })
            .catch(function (error) {
                let notify = notification({ message: "send failed! Try again.", type: 'error' });
		        notify();
                console.log(error);
            }); 
    }

    return (
        <>
            {loginDtl.role_id === "1" ?
                <HeaderDoc />
                :
                <Header />
            }
            <Row className='p-3'>
                <Col xs={12}>
                    <Card className='mb-3'>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <Label for="frmdate"> From Date </Label>
                                    <Input id="frmdate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} required />
                                </Col>
                                <Col md={3}>
                                    <Label for="todate"> To Date </Label>
                                    <Input id="todate" type='date' bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                </Col>
                                <Col md={3}>
                                    <Label for="type"> Type </Label>
                                    <Input
                                            id="type"
                                            bsSize="sm"
                                            type="select"
                                            onChange={(e) => setRoleId(e.target.value)}
                                        >
                                            <option value="1"> Doctor </option>
                                            <option value="3"> Clinic </option>
                                            
                                    </Input>
                                </Col>
                                <Col md={3}>
                                    <Button className='mt-4' bsSize="sm" color="primary" onClick={get_appointment_list}>Submit</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader> Booking Report </CardHeader>
                        <CardBody>
                        
                            <Row>
                                <Col xs="8"></Col>
                                <Col xs="4">
                                    <InputGroup>
                                        <InputGroupText>
                                            <FaSearch />
                                        </InputGroupText>
                                        <Input type="text" placeholder='Search ' onChange={filter_data_handler} />
                                    </InputGroup>
                                </Col>
                                <Col xs="12">
                                    <DataTable
                                        title=""
                                        columns={[
                                            {
                                                name: 'Account Name',
                                                selector: row => row.name,
                                                sortable: true,
                                            },
                                            {
                                                name: 'Address',
                                                selector: row => row.address,
                                                sortable: false,
                                                width: '25%',
                                                wrap: true
                                            },
                                            {
                                                name: 'Total Appointments Count',
                                                selector: row => row.count,
                                                sortable: false,
                                            },
                                            {
                                                name: 'Total Collected Amount',
                                                selector: row => row.fee,
                                                sortable: false,
                                            },
                                            {
                                                name: 'View',
                                                selector: row => row.action,
                                                sortable: false,
                                            }
                                        ]}
                                        data={filterList}
                                        striped
                                        highlightOnHover
                                        progressPending={loading}
                                    />
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Footer />

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={openModal}
            >
                <ModalHeader toggle={() => setOpenModal(false)}>
                    Appointment Details
                </ModalHeader>
                <ModalBody>
                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: ' Doctor Name',
                                            selector: row => row.name,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Patient Name',
                                            selector: row => row.patient,
                                            sortable: false,
                                            width: '30%'
                                        },
                                        {
                                            name: 'Booking Date',
                                            selector: row => row.booking_date,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Cancel Appointment',
                                            selector: row => row.cancel,
                                            sortable: false,
                                        }
                                    ]}
                                    data={appointmentDtl}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}
                                />
                    
                </ModalBody>

            </Modal>
        </>
    )
}
export default BookingReport;