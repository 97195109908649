
import { Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Home from './pages/Home';
import DoctorHome from './pages/DoctorHome';
import AgentHome from "./pages/AgentHome";
import AddDoctor from "./pages/AddDoctor";
import ManageDoctor from "./pages/ManageDoctor";
import Search from "./pages/appointment/Search";
import Appointment from "./pages/appointment/Appointment";
import WIP from './pages/WIP';
import AppointmentList from "./pages/AllAppointmentList";
import LeaveManage from "./pages/LeaveManage";
import BookingReport from "./pages/BookingReport";
import './style/main.scss';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Login/> } />
        <Route path="/home" element={ <Home/> } />
        <Route path="/doctor-home" element={ <DoctorHome/> } />
        <Route path="/agent-home" element={ <AgentHome/> } />
        <Route path="manage-doctor" element={ <ManageDoctor/> } />
        <Route path="add-doctor" element={ <AddDoctor/> } />
        <Route path="search" element={ <Search/> } />
        <Route path="appointment" element={ <Appointment/> } />
        <Route path="appointmentList" element={ <AppointmentList/> } />
        <Route path="leave-manage" element={ <LeaveManage/> } />
        <Route path="wip" element={ <WIP/> } />
        <Route path="BookingReport" element={ <BookingReport/> } />
      </Routes>
    </div>
  );
}

export default App;