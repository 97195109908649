import React, {useEffect, useState} from "react";

const Slot = ({select, ...props}) => {
    const [time, setTime] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    useEffect(() => {
        let times = [
            {"slot": "6:00", "doctor_slot_id": 1},
            {"slot": "6:30", "doctor_slot_id": 2},
            {"slot": "7:00", "doctor_slot_id": 3},
            {"slot": "7:30", "doctor_slot_id": 4},
            {"slot": "8:00", "doctor_slot_id": 5},
            {"slot": "8:30", "doctor_slot_id": 6},
            {"slot": "9:00", "doctor_slot_id": 7},
            {"slot": "9:30", "doctor_slot_id": 8},
            {"slot": "10:00", "doctor_slot_id": 9},
            {"slot": "10:30", "doctor_slot_id": 10},
            {"slot": "11:00", "doctor_slot_id": 11},
            {"slot": "11:30", "doctor_slot_id": 12},
            {"slot": "12:00", "doctor_slot_id": 13},
            {"slot": "12:30", "doctor_slot_id": 14},
            {"slot": "13:00", "doctor_slot_id": 15},
            {"slot": "13:30", "doctor_slot_id": 16},
            {"slot": "14:00", "doctor_slot_id": 17},
            {"slot": "14:30", "doctor_slot_id": 18},
            {"slot": "15:00", "doctor_slot_id": 19},
            {"slot": "15:30", "doctor_slot_id": 20},
            {"slot": "16:00", "doctor_slot_id": 21},
            {"slot": "16:30", "doctor_slot_id": 22},
            {"slot": "17:00", "doctor_slot_id": 23},
            {"slot": "17:30", "doctor_slot_id": 24},
            {"slot": "18:00", "doctor_slot_id": 25},
            {"slot": "18:30", "doctor_slot_id": 26},
            {"slot": "19:00", "doctor_slot_id": 27},
            {"slot": "19:30", "doctor_slot_id": 28},
            {"slot": "20:00", "doctor_slot_id": 29},
            {"slot": "20:30", "doctor_slot_id": 30},
            {"slot": "21:00", "doctor_slot_id": 31},
        ] ;
        setTime(times);   
    },[])

    let getSelected = (t) => {
        let i = [...selectedIndex];

        let no = i.findIndex((el)=> el == t.doctor_slot_id);

        if (no == -1) {
            i.push(t.doctor_slot_id);
        }else{ 
            i.splice(no, 1);
        }

        
        setSelectedIndex(i);
        select(i);
    }
    return (
        
        <div className='timeSlotPicker'>
            {time?.length > 0 && time.map((row, index) => {
                return(
                    <div className={selectedIndex.find((i)=> i==row.doctor_slot_id) ? 'timeSlot active' : 'timeSlot'} 
                    onClick={() => getSelected(row)} >
                        {row.slot}
                    </div>
                )
            })
            }    
        </div>
    )
}

export default Slot;