import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '../component/hocs/notification';
import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';
import {
    Row,
    Col,
    Input,
    Label,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    Button,
    Spinner
} from 'reactstrap';

import {BsEyeFill, BsEyeSlashFill} from 'react-icons/bs';

const Login = () => {
    
    const navigate = useNavigate();
    const [mode, setMode] = useState(1);
    const [userId, setUserId] = useState(null);
    const [password, setPassword] = useState(null);
    const [loginType, setLoginType] = useState("primary");
    const [loginTxt, setLoginTxt] = useState("ADMIN LOGIN");
    const [loading, setLoading] = useState(false);
    const [inputType, setInputType] = useState(false);


    const [forgetUserId, setForgetUserId] = useState('');
    const [otp, setOtp] = useState('');
    const [resetPassword, setResetPassword] = useState('');
    const [resetCnfPassword, setResetCnfPassword] = useState('');
    const [valid, setValid] = useState(false);

    const login_handler = (e) => {
        e.preventDefault();
        setLoading(true);

        let postData = {};
        if(loginTxt === "ADMIN LOGIN"){
            postData = {"role_id" : 2,"user_name" : userId,"password" : password}
        }else if(loginTxt === "DOCTOR LOGIN"){
            postData = {"role_id" : 1,"user_name" : userId,"password" : password}
        }else if(loginTxt === "CLINIC LOGIN"){
            postData = {"role_id" : 3,"user_name" : userId,"password" : password}
        }else if(loginTxt === "AGENT LOGIN"){
            postData = {"role_id" : 3,"user_name" : userId,"password" : password}
        }

        axios.post(mainUrl + 'Login/user_login', postData)
        .then(function (response) {
           console.log(response.data);
            if(response.data.role_id !== undefined && response.data.role_id !== "" && response.data.role_id !== null){
                //set login true 
                 localStorage.setItem('loggedIn', true);

                 localStorage.setItem('loginDetails', JSON.stringify(response.data))
     
                 // redirect to particular dashbord
                 if(response.data.role_id === "2"){
                     navigate('/home/');
                 }else if(response.data.role_id === "1"){
                    sessionStorage.setItem('doctor_id', response.data.doctor_id);
                     navigate('/doctor-home/');
                 }else if(response.data.role_id === "3"){
                     navigate('/agent-home/');
                 }

                let notify = notification({ message: "Login successfully.", type: 'success' });
		        notify();
                setLoading(false);
            }else{
                let notify = notification({ message: "User id and password is not matched. Try again.", type: 'error' });
		        notify();
                setLoading(false);
            }
        })
        .catch(function (error) {
            console.log(error); 
            setLoading(false);
        });
    }

    const get_varification_handler = () => {
        setLoading(true);
        let postData = {"email": forgetUserId, }

        axios.post(mainUrl + 'Admin/user_id_check', postData)
        .then(function (response) {
            if(response.data[0].otp > 10000){
                let notify = notification({ message: "OTP Sent successfully. Your OTP is "+response.data[0].otp+" ", type: 'success' });
                notify();
                setOtp(response.data[0].otp);
                setLoading(false);
    
                setMode(3);
            }else{
                let notify = notification({ message: "Wrong user id.", type: 'warn' });
                notify();
                setLoading(false);
            }
        })
        .catch(function (error) {
            console.log(error); 
            setLoading(false);
        });

        
    }
    const varification_handler = () => {
        setLoading(true);
        let postData = {"email": forgetUserId, "otp": otp}

        axios.post(mainUrl + 'Admin/otp_varification', postData)
        .then(function (response) {
            if(response.data !== null ){
                let notify = notification({ message: "Varify successfully.", type: 'success' });
                notify();
                setLoading(false);
    
                setMode(4);
            }else{
                setLoading(false);
                let notify = notification({ message: "OTP not match.", type: 'warn' });
                notify();
            }
        })
        .catch(function (error) {
            console.log(error); 
            setLoading(false);
        });
    }
    const reset_password_handler = () => {

        if(valid){
            setLoading(true);
            let postData = {"email": forgetUserId, "password": resetPassword, }
    
            axios.post(mainUrl + 'Admin/password_set', postData)
            .then(function (response) {
                console.log(response.data);
                let notify = notification({ message: "Password reset successfully.", type: 'success' });
                notify();
                setLoading(false);
    
                setMode(1);
            })
            .catch(function (error) {
                console.log(error); 
                setLoading(false);
            });
        }else{
            let notify = notification({ message: "Password not match.", type: 'warn' });
            notify();
        }
    }

    const confirm_check = (e) => {
        if(e.target.value === resetPassword){
            setValid(true);
        }else{
            setValid(false);
        }
        setResetCnfPassword(e.target.value);
    }

    return (
        <div className='d-flex align-items-center vh-100 justify-content-center loginpage'>
            <Row className='w-100 justify-content-center'>
                <Col md={6} className="rounded-start loginImg">
                    
                </Col>
                {mode === 1 && 
                    <Col md={4} className="rounded-end bg-white " style={{padding:"60px 20px"}}>
                        <h3 className='mb-4'>Welcome Door to Doctor</h3>
                        <div className='d-flex justify-content-around align-items-center mb-3'>
                            <Button color="primary" onClick={()=>{setLoginType("primary");setLoginTxt("ADMIN LOGIN")}}>ADMIN</Button>
                            <Button color="warning" onClick={()=>{setLoginType("warning");setLoginTxt("DOCTOR LOGIN")}}>DOCTOR</Button>
                            <Button color="warning" onClick={()=>{setLoginType("warning");setLoginTxt("CLINIC LOGIN")}}>CLINIC</Button>
                            <Button className='text-white' color="danger" onClick={()=>{setLoginType("danger");setLoginTxt("AGENT LOGIN")}}>AGENT</Button>
                        </div>

                        <Form onSubmit={login_handler}>
                            <h4 className='mb-3'>SIGN IN</h4>
                            <FormGroup>
                                <Label for="userId">
                                    User ID
                                </Label>
                                <Input
                                    id="userId"
                                    name="userId"
                                    placeholder="Enter your user ID"
                                    type="text"
                                    bsSize="lg"
                                    onChange={(e) => setUserId(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">
                                    Password
                                </Label>
                                
                            </FormGroup>
                            <InputGroup>
                                <Input
                                    id="password"
                                    name="password"
                                    placeholder="Enter password"
                                    type={inputType ? 'text' : 'password'}
                                    bsSize="lg"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputGroupText className='cursor' onClick={()=>setInputType(!inputType)}>
                                { inputType ? 
                                        <BsEyeSlashFill size={20} /> :
                                        <BsEyeFill size={20} />
                                }
                                </InputGroupText>
                            </InputGroup>
                            
                            <div className='text-end mb-3 cursor' onClick={()=>setMode(2)}>Forget Password</div>
                            <Button block color={loginType} size="lg" disabled={loading}>
                                {loginTxt}
                                {loading && <Spinner className='ms-2' size="sm"></Spinner>}
                                
                            </Button>
                        </Form>
                    </Col>
                }

                {mode === 2 && 
                    <Col md={4} className="rounded-end bg-white " style={{padding:"60px 20px"}}>
                        <h3 className='mb-4'>Welcome Door to Doctor</h3>
                        <h4 className='mb-4'>Forget Password</h4>

                        <FormGroup>
                            <Label for="userId">
                                User ID
                            </Label>
                            <Input
                                id="userId"
                                name="userId"
                                placeholder="Enter your user ID"
                                type="text"
                                bsSize="lg"
                                value={forgetUserId}
                                onChange={(e) => setForgetUserId(e.target.value)}
                            />
                        </FormGroup>
                        <Button block color="primary" size="lg" disabled={loading} onClick={get_varification_handler} >
                            Get Varification code
                            {loading && <Spinner className='ms-2' size="sm"></Spinner>}
                        </Button>
                        <Button bsSize="sm" color='warning' className='mt-2 mb-3' onClick={()=>setMode(1)}><b>Back</b></Button>
                    </Col>
                }      

                {mode === 3 && 
                    <Col md={4} className="rounded-end bg-white " style={{padding:"60px 20px"}}>
                        <h3 className='mb-4'>Welcome Door to Doctor</h3>
                        <h4 className='mb-4'>Varify your code</h4>

                        <FormGroup>
                            <Label for="opt">
                                User ID
                            </Label>
                            <Input
                                id="opt"
                                name="opt"
                                placeholder="Enter your OTP"
                                type="text"
                                bsSize="lg"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </FormGroup>
                        <Button block color="primary" size="lg" disabled={loading} onClick={varification_handler} >
                            Varify
                            {loading && <Spinner className='ms-2' size="sm"></Spinner>}
                        </Button>
                        <Button bsSize="sm" color='warning' className='mt-2 mb-3' onClick={()=>setMode(2)}><b>Back</b></Button>
                    </Col>
                }        

                {mode === 4 && 
                    <Col md={4} className="rounded-end bg-white " style={{padding:"60px 20px"}}>
                        <h3 className='mb-4'>Welcome Door to Doctor</h3>
                        <h4 className='mb-4'>Reset your Password</h4>

                        <FormGroup>
                            <Label for="resetPassword">
                               Password
                            </Label>
                            <Input
                                id="resetPassword"
                                name="resetPassword"
                                placeholder="Enter your Password"
                                type="text"
                                bsSize="lg"
                                value={resetPassword}
                                onChange={(e) => setResetPassword(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="resetCnfPassword">
                                Confirm Password
                            </Label>
                            <Input
                                id="resetCnfPassword"
                                name="resetCnfPassword"
                                placeholder="Enter your Confirm Password"
                                type="text"
                                bsSize="lg"
                                value={resetCnfPassword}
                                onChange={confirm_check}
                                valid={valid}
                                invalid={!valid}
                            />
                        </FormGroup>
                        <Button block color="primary" size="lg" disabled={loading} onClick={reset_password_handler} >
                                Reset
                            {loading && <Spinner className='ms-2' size="sm"></Spinner>}
                        </Button>
                    </Col>
                }        
            </Row>
        </div>
    )
}

export default Login;