import React from 'react';

import classNames from 'classnames';

const TextHeader = ({name, bgColor, ...props}) => {
    const classes = classNames('map-pill', {
        [`bg-${bgColor}`]: bgColor,
      });
    return (
        <div className={`textWhite text-center p-2 mb-2 rounded ${classes}` }>
            <h6>{name}</h6>
        </div>
    )
}

export default TextHeader;