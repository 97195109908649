import React, {useState, useEffect} from 'react';
import { notification } from '../component/hocs/notification';
import Header from '../component/layout/Header';
import HeaderDoc from '../component/layout/HeaderDoc';
import HeaderAgent from '../component/layout/HeaderAgent';
import Footer from '../component/layout/Footer';

import {todayDate} from '../component/hocs/formatDate';

import AppointmentList from '../component/utility/appointment/AppointmentList';
import AppointmentDetails from '../component/utility/appointment/AppointmentDetails';

import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';
import LoadingSpinner from '../component/utility/LoadingSpinner';

import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardHeader,
    CardBody,

} from 'reactstrap';

const AllAppointmentList = () => {
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [frmDate, setFrmDate] = useState(todayDate());
    const [toDate, setToDate] = useState(todayDate());
    const [appointmentList, setAppointmentList] = useState('');
    const [loginDtl, setLoginDtl] = useState('');
   
    const [appointmentDtl, setAppointmentDtl] = useState(null);

    useEffect(() => {
        let loginDtl = JSON.parse(localStorage.getItem('loginDetails')); 
        setLoginDtl(loginDtl);
    }, [])

    const get_appointment_list = (e) => {
        e.preventDefault();
        setLoading(true);
        let loginDtl = JSON.parse(localStorage.getItem('loginDetails'));
        let doctorId = '0';
        if(loginDtl.doctor_id !== null && loginDtl.doctor_id !== undefined){
            doctorId = loginDtl.doctor_id;
        }
        let postData = {"doctor_id":doctorId, "to_date": frmDate, "from_date": toDate}
        axios.post(mainUrl + 'Admin/appointment_list_date_wish', postData)
            .then(function (response) {
                setLoading(false);
                setAppointmentList(response.data);
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error); 
            });
        
    }

    const openOrderDetail = (id) => {
        // appointment details API
        setLoading(true);
        let postData = {"booking_id" : id}
        axios.post(mainUrl + 'Booking/booking_details', postData)
        .then(function (response) {
            console.log(response.data[0]);
            setAppointmentDtl(response.data[0]);
            setOpenModal(true);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error); 
            setLoading(false);
        });
    }
    return(
        <>
            { loginDtl.role_id === "1" && 
                <HeaderDoc />
            }
            { loginDtl.role_id === "2" && 
                <Header />
            }
            { loginDtl.role_id === "3" && 
                <HeaderAgent />
            }
                <Row className='p-3'>
                    <Col xs={12}>
                        <Card className='mb-3'>
                            <CardBody>
                                <Row> 
                                    <Col md={3}>
                                        <Label for="frmdate"> From Date </Label>
                                        <Input id="frmdate" type='date' bsSize="sm" max={toDate} value={frmDate} onChange={(e) => setFrmDate(e.target.value)} required />
                                    </Col>
                                    <Col md={3}>
                                        <Label for="todate"> To Date </Label>
                                        <Input id="todate" type='date' bsSize="sm" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
                                    </Col>
                                    <Col md={3}>
                                        <Button className='mt-4' bsSize="sm" color="primary" onClick={get_appointment_list}>Submit</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>    
                        <Card>
                            <CardHeader> Appointment List</CardHeader>
                            <CardBody>
                                <Row>

                                    {appointmentList &&
                                        appointmentList.map((row, index) => {
                                            return(
                                                <Col xs={4}>
                                                    <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="secondary" />
                                                </Col>
                                            )
                                        })
                                    }
                                    
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            <Footer />

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={openModal}
            >
                <ModalHeader toggle={() => setOpenModal(false)}>
                    Appointment Details
                </ModalHeader>
                <ModalBody>
                    
                    <AppointmentDetails data={appointmentDtl} />
                </ModalBody>

            </Modal>

            { loading && <LoadingSpinner /> }
        </>
    )
}
export default AllAppointmentList;