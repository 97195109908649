import React from 'react';
import {Row, Col, Card, CardText} from 'reactstrap';


import {FaUserMd, FaUserInjured, FaRupeeSign,} from 'react-icons/fa';
import {BsCardChecklist, BsClipboardCheck} from 'react-icons/bs';
import {BiRupee} from 'react-icons/bi';
import {MdCancelPresentation} from 'react-icons/md';

const DisplayBox = ({name, icon, value, ...props}) => {
    return (
        <Card className="primaryBg mb-2">
            <CardText className='p-2'>
                <Row>
                    <Col md={5} className="d-flex align-items-center justify-content-center">
                        { icon === "1" && 
                            <FaUserMd size={50} color="#fff"/>
                        }
                        { icon === "2" && 
                            <FaRupeeSign size={50} color="#fff"/>
                        }
                        { icon === "3" && 
                            <BsCardChecklist size={50} color="#fff"/>
                        }
                        { icon === "4" && 
                            <FaUserInjured size={50} color="#fff"/>
                        }
                        { icon === "5" && 
                            <BsClipboardCheck size={50} color="#fff"/>
                        }
                        { icon === "6" && 
                            <MdCancelPresentation size={50} color="#fff"/>
                        }
                        { icon === "7" && 
                            <BiRupee size={50} color="#fff"/>
                        }
                    </Col>
                    <Col md={7}>
                        <h3>{value}</h3>
                        <h5>{name}</h5>
                    </Col>
                </Row>
            </CardText>
        </Card>
    )
}

export default DisplayBox;