import React from 'react';
import { Link } from "react-router-dom";
import { FaHeartbeat } from "react-icons/fa";
import {
    Row,
    Col,
    Button,

} from 'reactstrap';


function Banner({btn, ...props}) {
    return (
        <Row className="banner">
                <Col className='p-5 mt-4' md="4">
                <div>Caring for better life</div>
                <h1>Leading the way in medical excellence</h1>
                <div>Earth greater grass for good. Place for divide evening yielding them that. Creeping beginning over gathered brought.</div>
                <div className='text-center mt-3'>
                    {btn && <Link to="/search">
                        <Button color="primary"><FaHeartbeat /> TAKE APPOINTMENT</Button>
                    </Link> }
                </div>     
            </Col>
        </Row>
    )

}
export default Banner;