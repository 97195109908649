import React from 'react';

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
  } from 'reactstrap';
  
 
  import { Chart as ChartJS } from 'chart.js/auto'
  import { Bar, Doughnut } from 'react-chartjs-2';

let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] ;
    const barOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: ' ',
        },
      },
    };
    
    const barData = {
        labels: labels,
        datasets: [{
          label: 'Appointment',
          data: [65, 59, 80, 81, 56, 55, 40],
          backgroundColor: [
            '#244cfd',
            '#15e4fd',
            '#244cfd',
            '#15e4fd',
            '#244cfd',
            '#15e4fd',
            '#244cfd',
            '#15e4fd',
            
          ],
        }]
      };

      const chartData = {
        maintainAspectRatio: true,
        responsive: true,
        labels: [
            'Children',
            'Male',
            'Female'
          ],
        datasets: [{
            label: 'My First Dataset',
            data: [80, 50, 30],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
          }]
      };
      const chartOptions = {
        legend: {
          position: "bottom",
          
        },
        responsive: true,
        elements: {
          arc: {
            borderWidth: 1
          }
        },
      };
const GraphView = () => {
    return(
        <Row className='p-2'>
            <Col md={8}>
                <Card>
                    <CardHeader className='primaryBg text-light'>Appointments <small>[Last One Year]</small></CardHeader>
                    <CardBody>
                        <Bar options={barOptions} data={barData} /> 
                    </CardBody>
                </Card>
            </Col>
            <Col md={4} >
                <Card>
                    <CardHeader className='primaryBg text-light'>Patient by Gender <small>[Last Month]</small></CardHeader>
                    <CardBody>
                        <Doughnut
                            data={chartData}
                            options={chartOptions}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default GraphView;