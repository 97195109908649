import React, {useState, useEffect} from 'react';
import { notification } from '../component/hocs/notification';
import Header from '../component/layout/Header';
import HeaderDoc from '../component/layout/HeaderDoc';
import Footer from '../component/layout/Footer';
import Calendar from 'react-calendar';
import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';
import 'react-calendar/dist/Calendar.css';
import {
    Row,
    Col,
    Button,
    Card,
    CardHeader,
    CardBody,
    Alert,
    Spinner

} from 'reactstrap';

const LeaveManage = () => {
    const [loading, setLoading] = useState(false);
    const [loginDtl, setLoginDtl] = useState('');
    const [leaveList, setLeaveList] = useState('');

    const [frDate, setFrDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [selDate, setSelDate] = useState();

    useEffect(() => {
        let loginDtl = JSON.parse(localStorage.getItem('loginDetails')); 
        setLoginDtl(loginDtl);
        getLeaveList(loginDtl.doctor_id);
    }, [])

    const getLeaveDate = (val) => {
        //console.log(val);
        let fDate = getDateFormate(val[0]);
        setFrDate(fDate);
        let tDate = getDateFormate(val[1]);
        setToDate(tDate);
    }

    const appliedLeave_handler = () => {

        if(frDate && toDate){
            let postData = {"doctor_id":loginDtl.doctor_id ,"fr_date": frDate, "to_date": toDate};
            console.log(postData);
            setLoading(true);
            axios.post(mainUrl + 'Doctor/holiday_apply', postData)
            .then(function (response) {
                setLoading(false);
                let notify = notification({ message: "Submit successfully.", type: 'success' });
                notify();
            })
            .catch(function (error) {
                setLoading(false);
                setFrDate('');
                setToDate('');
                let notify = notification({ message: "Submit failed! Try again.", type: 'error' });
                notify();
            });
        }else{
            let notify = notification({ message: "Please Select Dates", type: 'warn' });
            notify();
        }
    }

    const getLeaveList = (id) => {
        const postData = {"doctor_id":id }
        axios.post(mainUrl + 'Doctor/doctor_holiday_list', postData)
            .then(function (response) {
                
                let list = response.data.map((row,index) => {
                    return(
                        <Alert color="success">
                           <b>{getDateWordFormate(row.fr_date)}</b> TO <b>{getDateWordFormate(row.to_date)}</b>
                        </Alert>
                    )
                });
                setLeaveList(list);
            })
            .catch(function (error) {
                
                let notify = notification({ message: "No Leave list found!", type: 'error' });
                notify();
            });
    }

    const getDateFormate = (val) => {
        let dt = new Date(val);
        
        let cYear = dt.getFullYear();
        let cMonth = (dt.getMonth() + 1).toString().padStart(2, "0");
        let cdate = dt.getDate().toString().padStart(2, "0");

        let send = cYear + "-" + cMonth + "-" + cdate;

        return send;
    }

    const getDateWordFormate = (val) => {
        let dt = new Date(val);

        let send = dt.toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: 'numeric'}).replace(/ /g, '-')

        return send;
    }

    return(
        <>
            { loginDtl.role_id === "1" ? 
                <HeaderDoc />
                :
                <Header />
            }
                <Row className='p-3'>
                    <Col xs={8}>
                        <Card className='mb-3'>
                            <CardHeader> Leave Management</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <div className='text-center ms-4'>
                                            <Calendar onChange={getLeaveDate} minDate={new Date()} selectRange={true} value={selDate} />
                                        </div>
                                        <Button className='mt-3' bsSize="sm" color="primary" disabled={loading} 
                                        block onClick={appliedLeave_handler}>Apply Leave {loading && <Spinner className='ms-2' size="sm"></Spinner>}</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Alert color="primary">
                                            You Can Manage your leave here.
                                        </Alert>
                                        <Alert color="primary">
                                            First Select the date range in shown celender.
                                        </Alert>
                                        <Alert color="primary">
                                            Then click "Apply Leave" Button.
                                        </Alert>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>  
                    </Col>
                    <Col xs={4}>
                        <Card className='mb-3'>
                            <CardHeader> Your Leave List </CardHeader>
                            <CardBody>
                                {leaveList}
                            </CardBody>
                        </Card>    
                    </Col>
                </Row>
            <Footer />
        </>
    )
}
export default LeaveManage;