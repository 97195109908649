import React, {useState, useEffect} from 'react';
import HeaderDoc from './../component/layout/HeaderDoc';
import Footer from './../component/layout/Footer';

import ProfileHome from '../component/utility/home/ProfileHome';
import DisplayBox from '../component/utility/home/DisplayBox';
import AppointmentList from '../component/utility/appointment/AppointmentList';
import CustomerReview from '../component/utility/home/CustomerReview';
import TextHeader from '../component/utility/home/TextHeader';
import AppointmentDetails from '../component/utility/appointment/AppointmentDetails';

import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody

} from 'reactstrap';

const DoctorHome = () => {
    const [openModal, setOpenModal] = useState(false);
    const [allCount, setAllCount] = useState(null);
    const [loginDetail, setLoginDetail] = useState(null);
    const [currAppointment, setCurrAppointment] = useState(null);
    const [futureAppointment, setFutureAppointment] = useState(null);
    const [appointmentDtl, setAppointmentDtl] = useState(null);

    useEffect(() => {
        let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        setLoginDetail(loginDetails);
        let postData =  {"user_id" : loginDetails.doctor_id};
        axios.post(mainUrl + 'Doctor/doctor_all_count', postData)
        .then(function (response) {
           console.log(response.data);
           setAllCount(response.data);
        })
        .catch(function (error) {
            console.log(error); 
        });


        let postData2 =  {"user_id" : loginDetails.doctor_id};
        axios.post(mainUrl + 'Doctor/doctor_booking_list', postData2)
        .then(function (response) {
           console.log(response.data);
           if(response.data.appointments_list_today[0].booking_id == 0){
            setCurrAppointment(null);
           }else{
            setCurrAppointment(response.data.appointments_list_today);
           }
           
           if(response.data.appointments_list_future[0].booking_id == 0){
            setFutureAppointment(null);
           }else{
            setFutureAppointment(response.data.appointments_list_future);
           }
           
        })
        .catch(function (error) {
            console.log(error); 
        });


    }, [])

    const openOrderDetail = (id) => {
        // appointment details API
        let postData = {"booking_id" : id}
        axios.post(mainUrl + 'Booking/booking_details', postData)
        .then(function (response) {
            console.log(response.data);
            setAppointmentDtl(response.data);
            setOpenModal(true);
        })
        .catch(function (error) {
            console.log(error); 
        });
    }

    return (
        <div>
            <HeaderDoc />

            <Row className='p-2'>
                <Col md={6}>
                    <ProfileHome name={loginDetail?.name} detail={loginDetail?.phone_number} imgSrc={require('../assets/img/doc.png')}/>
                </Col>
                <Col md={3}>
                    <DisplayBox name="Today Appointment" icon="3" value={allCount?.today_booking} />
                    <DisplayBox name="Canceled" icon="6" value={allCount?.cancel_booking} />
                </Col>
                <Col md={3}>
                    <DisplayBox name="Total Appointment" icon="5" value={allCount?.total_booking} />
                    <DisplayBox name="Patient" icon="4" value="0" />
                </Col>
            </Row>

            <Row className='p-2'>
                
                <Col md={4}>
                    <TextHeader name="Today Appointments" bgColor="primary" />
                    
                    {currAppointment && 
                        currAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="primary" />
                            )
                        })
                    }
                    {!currAppointment && 
                        <Card className='mb-2 cursor' color="primary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
                </Col>
                <Col md={4}>
                    <TextHeader name="Upcoming Appointments" bgColor="secondary" />

                    {futureAppointment && 
                        futureAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="secondary" />
                            )
                        })
                    }
                    {!futureAppointment && 
                        <Card className='mb-2 cursor' color="secondary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
                        
                </Col>
                <Col md={4}>
                   <CustomerReview />
                </Col>
            </Row>

            <div className='borderBottom'></div>

            <Footer />

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={openModal}
            >
                <ModalHeader toggle={() => setOpenModal(false)}>
                    Appointment Details
                </ModalHeader>
                <ModalBody>
                    
                    <AppointmentDetails data={appointmentDtl} />
                </ModalBody>

            </Modal>
        </div>
    )
}

export default DoctorHome;