import React from 'react';

import {
    Card,
    CardBody
} from 'reactstrap';    

const AppointmentList = ({color, openOrderDetail, data, ...props}) => {
    return(
        <Card className='mb-2 cursor' color={color} inverse onClick={openOrderDetail} >
            <CardBody>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>Appointment Number</div>
                    <div>{data?.booking_id}</div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>Date of Appointment</div>
                    <div>{data?.booking_date.split(" ")[0].split('-').reverse().join('-')}</div>
                </div>
                <div className='d-flex justify-content-between'>
                    <div className='fw-bold'>Timing</div>
                    <div>{data?.slot}</div>
                </div>
            </CardBody>
        </Card>
    )
}

export default AppointmentList;