import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../component/layout/Header";
import Footer from "../component/layout/Footer";
import { notification } from "../component/hocs/notification";
import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';

import DoctorDisplay from "../component/utility/DoctorDisplay";
import { Row, Col, Input, Spinner,InputGroup,InputGroupText } from "reactstrap";
import {FaEdit, FaSearch} from 'react-icons/fa';

const ManageDoctor = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [doctorsList, setDoctorsList] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [filterList, setFilterList] = useState('');

    useEffect(() => {
        get_all_doctor();
    },[])

    const get_all_doctor = () => {
        setLoading(true);
        axios({
            method: 'post',
            url: mainUrl + 'Admin/all_doctor',
            headers: { 'Content-Type': 'application/JSON', }
            })
            .then(function (response) {
                console.log(response.data)
                setDoctorsList(response.data);
                setFilterList(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
                let notify = notification({ message: "Doctors list not found! Try Again", type: 'error' });
                notify();
            });
    }

    const search_handler = (val) => {
        setSearchTxt(val);
        setLoading(true);

        if(doctorsList.length > 0){
            let tempSearchData = doctorsList.filter (item => {
                const query = val.toLowerCase();
                return ( 
                    item.name.toLowerCase().indexOf(query) >= 0 ||
                    item.deparment_name.toLowerCase().indexOf(query) >= 0   
                )
            })
            setFilterList(tempSearchData);
            setLoading(false);
        }
    }

    return(
        <div>
            <Header />
            <Row className="p-2">
                <Col md={12} className="primaryBg rounded textWhite mb-2"> 
                    <h4>All Doctors List</h4>
                </Col>
                <Col sm={{ offset: 9, size: 3 }} className="mb-3">
                    <InputGroup size="sm">
                        <InputGroupText> <FaSearch /> </InputGroupText>
                        <Input type="search" placeholder='Search' value={searchTxt} onChange={(e)=>search_handler(e.target.value)} />
                    </InputGroup>
                </Col>
                { filterList.length > 0 ?
                    filterList.map((row)=> {
                        return (<DoctorDisplay data={row} reset={get_all_doctor} />)
                    })
                    :
                    <div className="p-2 mt-3 text-center">
                        <Spinner color="primary" className="" size="lg">loading...</Spinner>
                    </div>
                }
                
            </Row>
            <Footer />
        </div>
    )
}

export default ManageDoctor;