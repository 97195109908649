import React, {useState, useEffect} from 'react';
import HeaderAgent from './../component/layout/HeaderAgent';
import Footer from './../component/layout/Footer';
import axios from 'axios';
import { mainUrl } from '../component/hocs/mainUrl';

import ProfileHome from '../component/utility/home/ProfileHome';
import DisplayBox from '../component/utility/home/DisplayBox';
import AppointmentList from '../component/utility/appointment/AppointmentList';
import TextHeader from '../component/utility/home/TextHeader';
import AppointmentDetails from '../component/utility/appointment/AppointmentDetails';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
    CardBody

} from 'reactstrap';
import Banner from '../component/utility/Banner';
import LoadingSpinner from '../component/utility/LoadingSpinner';

function AgentHome() {
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [allCount, setAllCount] = useState(null);
    const [loginDetail, setLoginDetail] = useState(null);
    const [currAppointment, setCurrAppointment] = useState(null);
    const [futureAppointment, setFutureAppointment] = useState(null);
    const [oldAppointment, setOldAppointment] = useState(null);
    const [appointmentDtl, setAppointmentDtl] = useState(null);

    useEffect(() => {
        setLoading(true);
        let loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
        setLoginDetail(loginDetails);
        let postData = {"user_id": loginDetails.user_id}
        axios.post(mainUrl + 'Clini/cli_all_count', postData)
        .then(function (response) {
            setLoading(false);
           setAllCount(response.data);
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false); 
        });
        
        
        axios.post(mainUrl + 'Clini/cli_booking_list', postData)
        .then(function (response) {
           console.log(response.data);
           if(response.data.appointments_list_today[0].booking_id == 0){
            setCurrAppointment(null);
           }else{
            setCurrAppointment(response.data.appointments_list_today);
           }
           
           if(response.data.appointments_list_future[0].booking_id == 0){
            setFutureAppointment(null);
           }else{
            setFutureAppointment(response.data.appointments_list_future);
           }

           if(response.data.appointments_list_old[0].booking_id == 0){
            setOldAppointment(null);
           }else{
            setOldAppointment(response.data.appointments_list_old);
           }

        })
        .catch(function (error) {
            console.log(error); 
        });


    }, [])

    const openOrderDetail = (id) => {
        // appointment details API
        let postData = {"booking_id" : id}
        axios.post(mainUrl + 'Booking/booking_details', postData)
        .then(function (response) {
            console.log(response.data);
            setAppointmentDtl(response.data);
            setOpenModal(true);
        })
        .catch(function (error) {
            console.log(error); 
        });
    }

    return (
        <div>
            <HeaderAgent />

            <Row className='p-2'>
                <Col md={6}>
                    <ProfileHome name={loginDetail?.name} detail={loginDetail?.phone_number} imgSrc={require('../assets/img/agent.png')}/>
                </Col>
                <Col md={3}>
                    <DisplayBox name=" Appointments" icon="3" value={allCount?.booking} />
                    <DisplayBox name="Canceled" icon="6" value="00" />
                </Col>
                <Col md={3}>
                    <DisplayBox name="Patient" icon="4" value={allCount?.user} />
                    <DisplayBox name="Earnings" icon="7" value="00" />
                </Col>
            </Row>

            <Banner btn={true}/>

            <Row className='p-2'>
                <Col md={12} className="primaryBg rounded textWhite mb-2"> 
                    <h4>Appointments List</h4>
                </Col>
                <Col md={4}>
                <TextHeader name="Today" bgColor="primary" />
                    {currAppointment &&
                        currAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="primary" />
                            )
                        })
                    }
                    {!currAppointment && 
                        <Card className='mb-2 cursor' color="primary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
                </Col>
                <Col md={4}>
                <TextHeader name="Future" bgColor="warning" />
                    {futureAppointment &&
                        futureAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="warning" />
                            )
                        })
                    }
                    {!futureAppointment && 
                        <Card className='mb-2 cursor' color="warning" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
                </Col>
                <Col md={4}>
                    <TextHeader name="Completed" bgColor="secondary" />

                    {oldAppointment &&
                        oldAppointment.map((row, index) => {
                            return(
                                <AppointmentList key={index} openOrderDetail={() => openOrderDetail(row.booking_id)} data={row} color="secondary" />
                            )
                        })
                    }
                    {!oldAppointment && 
                        <Card className='mb-2 cursor' color="secondary" inverse >
                            <CardBody>
                                No Appointment
                            </CardBody>
                        </Card>    
                    }
                </Col>
            </Row>

            <div className='borderBottom'></div>

            <Footer />

            <Modal
                fullscreen={false}
                size="lg"
                centered
                scrollable
                isOpen={openModal}
            >
                <ModalHeader toggle={() => setOpenModal(false)}>
                    Appointment Details
                </ModalHeader>
                <ModalBody>
                    
                    <AppointmentDetails data={appointmentDtl} />
                </ModalBody>
            </Modal>
            { loading &&
                <LoadingSpinner />

            }
        </div>
    )
}

export default AgentHome;